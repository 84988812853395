import React from "react"
import styled from 'styled-components'
import { useQuery } from 'react-query'
import {
  Buttons, Flex,
  GreenBarSection, HeaderBanner,
  Text, WhiteSection, Vis, DESKTOP_ONLY_CSS_WRAPPER, TABLET_ONLY_CSS_WRAPPER, MOBILE_ONLY_CSS_WRAPPER,
  ButtonLinkOutline,
  ButtonGreen
} from '../components/common'
import Layout from "../components/layout"
import { navTo } from '../utils/navigation'
import { Accordion, AccordionContext, Form } from 'react-bootstrap'
import NameIcon from '../images/Icon_3_Name_occupation_contact_36px.svg'
import AccountBalanceIcon from '../images/Icon_3_Account_balance.svg'
import TransactionDetailIcon from '../images/Icon_3_Transaction_36px.svg'
import DirectDebitIcon from '../images/Icon_3_Direct_debits_36px.svg'
import SavedPayeesIcon from '../images/Icon_3_Saved_Payees.svg'
import TickboxInactive from '../images/Tickbox_inactive.svg'
import TickboxActive from '../images/Tickbox_active.svg'
import IconOpenSvg from '../images/Icon_chevron_open.svg'
import IconCloseSvg from '../images/Icon_chevron_hide.svg'
import api from '../utils/api'
import { Breadcrumb } from "../components/Breadcrumb"
import { IS_PROD } from "../environment"
import screenListen from '../utils/screenListener'
import GlobalStoreContext from '../components/GlobalContext'

const IconOrder = {
  'ACCOUNT_BALANCE_AND_DETAILS': AccountBalanceIcon,
  'DIRECT_DEBITS_AND_SCHEDULED_PAYMENTS': DirectDebitIcon,
  'NAME_AND_OCCUPATION_AND_CONTACT_DETAILS': NameIcon,
  'SAVED_PAYEES': SavedPayeesIcon,
  'TRANSACTION_DETAILS': TransactionDetailIcon,
  'PROFILE': NameIcon,
}

const InfoIcon = styled.img`
  height:30px;
  width:30px;
  margin-right: 10px 
`

const Checkbox = styled.img`
  height:25px;
  width:25px;
  margin-right: 20px;
  border: 1px solid #373737;
`

const IconBox = styled.img`
  height:18px;
  width:18px;
`

const HeadingSelectorBox = styled.div`
  /* Auto Layout */

  display: flex;
  flex-grow: 0;
  flex-direction: row;
  align-items: center;
  padding: 17px 5px 17px 17px;

  /* Inside Auto Layout */

  flex: none;
  order: 0;
  align-self: stretch;
  
  font-family: Arial;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 22px;
  color: #373737;
  border-radius: 0px 0px 5px 5px;

  word-wrap: break-word;
  overflow-wrap: break-word;
  
`

const AccordionBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px 17px;
  border-radius: 0px 0px 5px 5px;
  background: #F5FAFF;
  /* Inside Auto Layout */

  flex: none;
  order: 0;
  flex-grow: 1;
  margin: 0px 0px;

`

const AccordionBodyBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 17px;
  background: green;
  background: #FBFDFF;
`

const TransactionToolTips = styled.span`
  border-radius: 6px;
  padding: 5px 15px;
  background: rgb(245, 250, 255);
  color: black;
  font-size: 14px;
`

const IncludeToolTipFlex = styled(Flex)`
  &:hover .tooltips{
    visibility: visible;
  }
  position: relative;
`

const OverLay = styled.div`
  content: " ";
  z-index: 10;
  display: block;
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.4)
`

const checkAtLeaseOneSelected = (list) => {
  return Object.keys(list).some((x) => list[x] === true)
}

const SelectData = "Select your data"

const ContextAwareToggle = ({ children, eventKey, callback }) => {
  const currentEventKey = React.useContext(AccordionContext);

  // const decoratedOnClick = useAccordionToggle(
  //   eventKey,
  //   () => callback && callback(eventKey),
  // );

  const isCurrentEventKey = currentEventKey === eventKey;

  return (
    <Accordion.Toggle as={AccordionBox} eventKey="0" style={{ cursor: 'pointer' }}>
      <div style={{ color: '#585D62', fontFamily: 'Arial', fontStyle: 'normal', fontWeight: 'normal', fontSize: '14px', lineHeight: '2px' }}>{isCurrentEventKey ? 'Close' : `What's included`}</div>
      <IconBox style={{ marginRight: '5px' }} src={isCurrentEventKey ? IconCloseSvg : IconOpenSvg} />
    </Accordion.Toggle>
  );
}


const ConsentList = ({ consentList, data, inx, isAccountSelected, handleClickEvent, checkList, isCommercial, setVersion, version }) => {
  const { width } = screenListen()
  let baseWidth = 130
  if (width > 200 && width < 992) {
    const scale = width * 0.17
    baseWidth = baseWidth + scale
  }
  if (width > 992 && width) {
    let scale = width * 0.14
    if (width > 993 && width < 1074) {
      scale = width * 0.1
    }
    baseWidth = baseWidth + scale
    if (baseWidth > 300) {
      baseWidth = 300
    }
  }

  const showToolTips = data === 'TRANSACTION_DETAILS' && isAccountSelected === false
  // const hasV2Version = (data === 'NAME_AND_OCCUPATION_AND_CONTACT_DETAILS'
  //   || data === 'ACCOUNT_BALANCE_AND_DETAILS'
  //   || data === 'SAVED_PAYEES') && isCommercial

  const hasV2Version = false

  const handleChange = (cluster, section, value) => {
    setVersion(pv => {
      pv[cluster][section] = value.toString()
      return { ...pv }
    })
  }


  return (
    <Flex flex="col" key={inx} style={{ margin: '0px 18px' }}>
      {/* top row */}
      <IncludeToolTipFlex flex="between vcenter" style={{ cursor: showToolTips ? 'not-allowed' : 'pointer', marginBottom: '1px', background: '#F5FAFF', borderRadius: '9px 9px 0px 0px' }} onClick={() => !showToolTips && handleClickEvent(data)} >
        {
          showToolTips && <OverLay />
        }
        <HeadingSelectorBox style={{ flexWrap: 'wrap', position: 'relative' }}>
          <InfoIcon alt={Object.keys(IconOrder)[inx]} src={IconOrder[data]} />
          <Text.DefaultText style={{ width: `${baseWidth}px`, maxWidth: '300px', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', color: '#373737', fontFamily: 'Arial' }}>{consentList[data].Consent}</Text.DefaultText>
        </HeadingSelectorBox>
        <Checkbox flex='grow' src={checkList[data] ? TickboxActive : TickboxInactive} />
      </IncludeToolTipFlex>
      {(data === 'TRANSACTION_DETAILS' && showToolTips) &&
        <TransactionToolTips>We need "Account balance and details" to be selected first for this option</TransactionToolTips>
      }
      {/* accordion */}
      <Flex className="bootstrap" style={{ marginBottom: '10px', background: '#F5FAFF' }}>
        <Accordion style={{ width: '100%' }}>
          <Accordion.Collapse eventKey="0">
            <AccordionBodyBox style={{ color: '#373737', fontFamily: 'Arial', fontStyle: 'normal', fontSize: '16px', lineHeight: '24px' }}>
              <span style={{ fontWeight: 'bold' }}>Included{data === 'TRANSACTION_DETAILS' && ' (one month only)'}: </span>
              {consentList[data]?.Permissions.map(((x, ix) => <li key={ix}>{x}</li>))}
              {
                hasV2Version &&
                <div className="pt-2">
                  <Form.Group className="mb-3" controlId="version">
                    <Form.Label>Version</Form.Label>
                    <Form.Control type="number" placeholder="Version" value={version[data]['version']} onChange={e => handleChange(data, 'version', e.target.value)} />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="version">
                    <Form.Label>Min version</Form.Label>
                    <Form.Control type="number" placeholder="Min Version" value={version[data]['minVersion']} onChange={e => handleChange(data, 'minVersion', e.target.value)} />
                  </Form.Group>
                </div>
              }
            </AccordionBodyBox>

          </Accordion.Collapse>

          <ContextAwareToggle eventKey="0" />

        </Accordion>
      </Flex>
    </Flex>
  )
}

const ButtonDelay = (navigateFn, payload) => {
  setTimeout(() => {
    if (payload) {
      navigateFn(payload)
    } else {
      navigateFn()
    }
  }, 300)
}


const SelectYourDataPage = (props) => {

  let { location: { state = {} } = {} } = props
  if (state === null) state = {}
  let { bank = '[no bank provided]', selectedCheckList = null, selectedVersion = null, isCommercial = false } = state
  const { durationSec, par } = state
  const [isAccountSelected, setIsAccountSelected] = React.useState(selectedCheckList ? selectedCheckList.ACCOUNT_BALANCE_AND_DETAILS : false)

  const { amendConsent } = React.useContext(GlobalStoreContext)

  let { isLoading: userIsLoading, data: userData = null, isSuccess: userIsSuccess } = useQuery('users', async () => {
    return new Promise((resolve, reject) => {
      api.getUserDetail().then((returnData) => resolve(returnData.data)).catch(err => reject(err))
    })
  }, {
    retry: 1,
    refetchOnWindowFocus: false
  })

  // storing version detail for each section
  const [requestVersion, setRequestVersion] = React.useState(selectedVersion ? selectedVersion : {
    'ACCOUNT_BALANCE_AND_DETAILS': {
      version: '',
      minVersion: ''
    },
    'DIRECT_DEBITS_AND_SCHEDULED_PAYMENTS': {
      version: '',
      minVersion: ''
    },
    'NAME_AND_OCCUPATION_AND_CONTACT_DETAILS': {
      version: '',
      minVersion: ''
    },
    'SAVED_PAYEES': {
      version: '',
      minVersion: ''
    },
    'TRANSACTION_DETAILS': {
      version: '',
      minVersion: ''
    },
    'PROFILE': {
      version: '',
      minVersion: ''
    }
  })

  console.log("Request version", requestVersion)

  // Load if AmendRecord is selected 

  React.useEffect(() => {
    if (amendConsent) {
      // load the bank detail
      bank = amendConsent?.bank?.logoUri
    }
  }, [amendConsent])

  const sortConsentDisplayOrder = (a, b) => {
    if (consentList[a].DisplayIndex < consentList[b].DisplayIndex) {
      return -1
    }
    if (consentList[a].DisplayIndex > consentList[b].DisplayIndex) {
      return 1
    }
    return 0
  }

  // const placeHolder = (data) => {
  //   let placeholder = require('./placeholder-consent-lang.json')?.AboutDataCollection?.Clusters
  //   let newOrderedCluster = Object.keys(placeholder).map((x) => placeholder[x])
  //   return newOrderedCluster.sort(sortConsentDisplayOrder)
  // }

  const placeHolderDataTemplate = () => {
    const data = require('./placeholder-consent-lang.json')?.AboutDataCollection?.Clusters
    if (!isCommercial) {
      // Not commercial, remove profile
      delete data.PROFILE
    }
    return data
  }

  const { isLoading, isError, data: consentList = null, error } = useQuery('consentLang', async () => {
    await new Promise(resolve => setTimeout(resolve, IS_PROD ? 0 : 5000))
    const { data } = await api.getConsentLanguage()
    console.log("Get consent lang backend is done")
    if (data && !isCommercial) {
      // Not commercial, remove profile
      delete data?.AboutDataCollection?.Clusters.PROFILE
    }
    return data?.AboutDataCollection?.Clusters
    //  // perform sort based on DisplayIndex number
    //  let newOrderedCluster = []
    //  const returnedClusters = data?.AboutDataCollection?.Clusters
    //  if (returnedClusters){
    //   newOrderedCluster = Object.keys(returnedClusters).map((x) => returnedClusters[x])
    //   newOrderedCluster.sort(sortConsentDisplayOrder)
    //   console.log("newOrderedCluster",newOrderedCluster)
    //  }
    // return newOrderedCluster
  }, {
    refetchOnWindowFocus: false,
    placeholderData: placeHolderDataTemplate()
  })

  // Name, Account, Transaction, Direct debit and Saved Payees
  const [checkList, setCheckList] = React.useState(selectedCheckList ? selectedCheckList : {
    'ACCOUNT_BALANCE_AND_DETAILS': false,
    'DIRECT_DEBITS_AND_SCHEDULED_PAYMENTS': false,
    'NAME_AND_OCCUPATION_AND_CONTACT_DETAILS': false,
    'SAVED_PAYEES': false,
    'TRANSACTION_DETAILS': false,
    'PROFILE': false
  })


  const handleClickEvent = (data) => {
    if (data === 'ACCOUNT_BALANCE_AND_DETAILS' && checkList[data] === false) {
      // account is false and about to be selected
      setIsAccountSelected(true)
    }

    if (data === 'ACCOUNT_BALANCE_AND_DETAILS' && checkList[data] === true) {
      // if account is uncheck and transaction is selected, unselect it
      setIsAccountSelected(false) // account no longer selected
      if (checkList['TRANSACTION_DETAILS'] === true) {
        setCheckList(pv => {
          pv['TRANSACTION_DETAILS'] = false
          return { ...pv }
        })
      }
    }
    setCheckList(pv => {
      pv[data] = !pv[data]
      return { ...pv }
    })
  }

  const bankUri = bank === '[no bank provided]' ? null : bank.logoUri

  const ButtonGroup = () => {
    return (
      <Flex flex="row">        
        <ButtonLinkOutline
          style={{ padding: "0 30px", marginRight: '21px', minWidth: "50%" }}
          rel="noreferrer noopener"
          onClick={() => ButtonDelay(amendConsent ? navTo.configureyourconsent_2 : navTo.selectyourbank_5, { ...state })}>
          Back
        </ButtonLinkOutline>
        <ButtonGreen
        style={{ padding: "0 30px", minWidth: "50%" }}
          onClick={() => ButtonDelay(navTo.summary_7, { ...state, bank, checkList, requestVersion})}
          disabled={!checkAtLeaseOneSelected(checkList)}>
          Next
        </ButtonGreen>
      </Flex>

    )
  }

return (
  <Layout.ColumnLayout title={SelectData}>
    <HeaderBanner logoUrl={bankUri} withStep={true} hamburger={false} commercial={userIsSuccess} commercialProperties={userData} userIsLoading={userIsLoading}>
      <Breadcrumb from={2} to={6} />
    </HeaderBanner>
    {/* mobile only */}
    <Vis.MobileOnly flex="col">
      <WhiteSection flex="col" style={{ margin: '11px 10px 0px 10px' }}>
        <Vis.MobileOnly><Text.Steps>Step 2 of 6</Text.Steps></Vis.MobileOnly>
        <Text.H1 style={{ marginTop: '0px', marginBottom: '16px', color: '#373737', fontFamily: 'Arial', fontWeight: 'bold' }}>{SelectData}</Text.H1>
        <GreenBarSection style={{ color: '#373737', fontFamily: 'Arial' }}>Which data do you want us to collect from <b>{bank?.brandName}</b> for you?</GreenBarSection>
      </WhiteSection>


      {consentList && Object.keys(consentList).sort(sortConsentDisplayOrder).map((data, inx) => {
        return (
          <ConsentList key={inx} consentList={consentList} data={data} inx={inx}
            isAccountSelected={isAccountSelected} handleClickEvent={handleClickEvent}
            checkList={checkList} isCommercial={isCommercial} setVersion={setRequestVersion} version={requestVersion} />
        )
      })}

      <WhiteSection flex="row" style={{ margin: '50px 0px' }}>
        <ButtonGroup />
      </WhiteSection>
    </Vis.MobileOnly>


    <Vis.TabletOnly flex="col">
      <WhiteSection flex="col" style={{ margin: '0px 35px 0px 38px' }}>
        <Text.H1 style={{ color: '#373737', fontFamily: 'Arial', fontWeight: 'bold' }}>{SelectData}</Text.H1>
        <GreenBarSection style={{ color: '#373737', fontFamily: 'Arial' }}>Which data do you want us to collect from <b>{bank?.brandName}</b> for you?</GreenBarSection>
      </WhiteSection>

      <Flex flex="col" style={{ margin: '0px 40px', padding: '20px' }}>
        {consentList && Object.keys(consentList).sort(sortConsentDisplayOrder).map((data, inx) => {
          return (
            <ConsentList key={inx} consentList={consentList} data={data} inx={inx}
              isAccountSelected={isAccountSelected} handleClickEvent={handleClickEvent}
              checkList={checkList} isCommercial={isCommercial} setVersion={setRequestVersion} version={requestVersion} />
          )
        })}
      </Flex>

      <WhiteSection flex="row" style={{ margin: '50px 0px' }}>
        <ButtonGroup />
      </WhiteSection>
    </Vis.TabletOnly>

    <Vis.DesktopOnly flex="col">
      <WhiteSection flex="row" style={{ margin: '52px 50px 0px 100px' }}>
        <WhiteSection flex="col w50" style={{ padding: '0px' }}>
          <WhiteSection flex="col nogrow">
            <Text.H1 style={{ maxWidth: '509px', color: '#373737', fontFamily: 'Arial', fontWeight: 'bold' }}>{SelectData}</Text.H1>
            <GreenBarSection style={{ color: '#373737', fontFamily: 'Arial' }}>Which data do you want us to collect from <b>{bank?.brandName}</b> for you?</GreenBarSection>
          </WhiteSection>
          <WhiteSection flex="row" style={{ marginTop: "90px", padding: "0px 0px", width: '424px' }}>
            <ButtonGroup />
          </WhiteSection>
        </WhiteSection>
        <Flex flex="w50 col" style={{ paddingTop: '35px' }}>
          <Flex flex="col nogrow" style={{ margin: '0px 5px', padding: '20px 5px 20px 5px' }}>
            {consentList && Object.keys(consentList).sort(sortConsentDisplayOrder).map((data, inx) => {
              return (
                <ConsentList key={inx} consentList={consentList} data={data} inx={inx}
                  isAccountSelected={isAccountSelected} handleClickEvent={handleClickEvent}
                  checkList={checkList} isCommercial={isCommercial} setVersion={setRequestVersion} version={requestVersion} />
              )
            })}
          </Flex>
        </Flex>

      </WhiteSection>
      {/* {
          isCommercial && 
          <WhiteSection flex="row" style={{ margin: '0px 50px 0px 100px' }}>
            <pre style={{ padding: '10px 10px', backgroundColor: 'lightgrey' }}>
              Duration: {durationSec}, Pushed Authroisation Request:{JSON.stringify(par)}
              {`\n`}Bank: {JSON.stringify(bank.brandName)}</pre>
          </WhiteSection>
        } */}
    </Vis.DesktopOnly>

  </Layout.ColumnLayout >
)
}

export default SelectYourDataPage
